import styles from './navigation.module.css';
// import { displayCategoryVar } from '@/lib/cache';
import { projectTitleVar } from '../../lib/cache';
import { useReactiveVar } from '@apollo/client';
import { closeButtonIcon, menuButtonIcon } from '../../lib/svg';

import { useLocation, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

// import infoIcon from './MBZInfo2-02.svg';
import Menu from './menu';


export default function Navigation() {

	let { pathname, state } = useLocation();

	let projectTitle = useReactiveVar(projectTitleVar);
	// let projectTitle = state ? state.title : useReactiveVar(projectTitleVar);
	// console.log(state)

	// const [onProjectPage, setOnProjectPage] = useState();
	const [onHomePage, setOnHomePage] = useState( pathname === '/' );
	// const [onListPage, setOnListPage] = useState();
    const [menuOpen, setMenuOpen] = useState( false );

    useEffect(() => {
        // setOnProjectPage( pathname.startsWith('/projects/') && pathname.length > 10);
        // setOnListPage( pathname.startsWith('/projects') );
        setOnHomePage( pathname === '/' );

        setMenuOpen( open => { if (open) return !open } );
    }, [pathname]);

    return (
        <>
	    	<nav id="nav" className={styles.navContainer}>
		    	
		    	<div className="flex gap-4 items-center">

		    		<Link to="/" className={styles.name} > MBABAZI </Link>

		    		{ !onHomePage &&
		    			<>
		    				<div className={styles.bar}> | </div>
		    				<div className={styles.pageDetail}> {projectTitle} </div>
		    			</>
		    		}
		    		
		    	</div>

		    	<button className={styles.menuButton} onClick={() => setMenuOpen(!menuOpen) } >
		    		{ menuOpen ? closeButtonIcon(styles.svg) : menuButtonIcon(styles.svg) }
		    	</button>

			</nav>

			{ (menuOpen) && <Menu menuClose={() => { setMenuOpen(false) }} /> }

		</>
    );
}