
export const menuButtonIcon = (styles) => (
	<svg className={styles} id="a" xmlns="http://www.w3.org/2000/svg" width="20" height="36" viewBox="0 0 20 36">
		<path d="M19,30.19h-4.5c-.55,0-1-.45-1-1V13c0-.55-.45-1-1-1H2.84c-.55,0-1,.45-1,1v3.82c0,.55,.45,1,1,1h3.17c.55,0,1,.45,1,1v10.37c0,.55-.45,1-1,1H1c-.55,0-1,.45-1,1v3.81c0,.55,.45,1,1,1H19c.55,0,1-.45,1-1v-3.81c0-.55-.45-1-1-1Z" />
    	<path d="M10.26,7.97c2.21,0,4.01-1.78,4.01-3.98S12.47,0,10.26,0,6.25,1.78,6.25,3.98s1.79,3.98,4.01,3.98Z" />
    </svg>
)

export const closeButtonIcon = (styles) => (
	<svg className={styles} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<path d="M15.7438 2.31413C15.9391 2.11885 15.9391 1.8022 15.7438 1.60693L14.9313 0.794596C14.736 0.599392 14.4195 0.599393 14.2243 0.794596L8.74344 6.27406C8.54818 6.46927 8.23167 6.46927 8.03642 6.27406L2.55569 0.794703C2.36044 0.5995 2.04393 0.5995 1.84868 0.794703L1.03614 1.60704C0.840818 1.80231 0.840818 2.11896 1.03614 2.31423L6.51651 7.79324C6.71183 7.98851 6.71183 8.30516 6.51651 8.50044L1.03738 13.9782C0.842063 14.1735 0.842063 14.4901 1.03738 14.6854L1.84992 15.4977C2.04517 15.6929 2.36169 15.6929 2.55694 15.4977L8.03642 10.0196C8.23167 9.82441 8.54818 9.82441 8.74344 10.0196L14.223 15.4978C14.4183 15.693 14.7348 15.693 14.93 15.4978L15.7426 14.6855C15.9379 14.4902 15.9379 14.1736 15.7426 13.9783L10.2633 8.50044C10.068 8.30516 10.068 7.98851 10.2633 7.79324L15.7438 2.31413Z"/>
	</svg>
)