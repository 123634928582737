import { useRouteError, Link } from "react-router-dom";
import { projectTitleVar } from '../lib/cache';

export default function NotFound() {

    const error = useRouteError();
    // console.error(error);

    projectTitleVar('404');

    return (

        <div id="error-page">

            <h1>You might be lost. You might not</h1>

            <p>Let's go back <Link to="/" >home.</Link> </p>

        </div>
    );
}
