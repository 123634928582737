
import styles from './home.module.css';

import { gql } from '@apollo/client';
import { Link, useRouteLoaderData } from "react-router-dom";


const PROJECT_LIST_QUERY = gql`
    query GetProjectList($width: Int) {
        projectLists(where: { listName: "Featured" }) {
            projects(first: 12) {
                id
                title
                slug
                mainImage {
                    id
                    url(
                        transformation: {
                            document: { output: { format: webp } },
                            image: { resize: { width: $width } }
                        }
                    )
                }
            }
        }
    }
`;


const getFetchedImageWidth = () => {
    return (window.screen.availWidth > 1200)
        ? Math.floor(window.screen.availWidth/4)
        : (window.screen.availWidth > 1000)
            ? 400
            : 300
}

export async function GetProjectList (client) {
    // console.log('GetProjectList called')
    const { data } = await client.query({
        query: PROJECT_LIST_QUERY,
        variables: { "width": getFetchedImageWidth() }
    })
    return data;
}

export default function Home() {

    const data = useRouteLoaderData("root");

    const { projectLists: [ {projects} ] } = data;

    return (
        <div className={styles.homePageContainer}>

            <ul className={styles.projectListContainer}>
                { projects.map( ({ id, title, slug, mainImage }) => (

                    <Link
                        key={id}
                        id={id}
                        className={styles.projectListItem}
                        to={`/projects/${slug}`}
                    >
                        <img
                            width={getFetchedImageWidth()}
                            height={getFetchedImageWidth()}
                            className={styles.projectImage}
                            src={`${mainImage.url}`}
                            alt={`${title} project main image`}
                        />
                    </Link>

                )) }
            </ul>

            <h1 className={styles.homeText}> 
                Design & Illustration Portfolio of Sasha Mbabazi
            </h1>

        </div>
    );
}

