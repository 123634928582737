
import styles from './project.module.css';

import {
	Link,
	Outlet,
	useParams,
	useLocation,
	useLoaderData,
	useRouteLoaderData
} from 'react-router-dom';

import { useApolloClient, InMemoryCache } from '@apollo/client';
import { useState, useEffect } from 'react';

// import {  } from '@/lib/cache';
// import { displayCategoryVar, projectTitleVar } from '@/lib/cache';

// import ActiveProject from '@/components/ACTIVEPROJECT/activeProject';
import { useQuery, gql } from '@apollo/client'; 


// const PROJECT_BY_SLUG = gql`
// 	query Projects {
// 	    projectLists {
// 	    	projects(first: 12) {
// 	    		id
// 	            title
// 	            slug
// 	            mainImage { url }
// 	        }
// 	    }
// 	}
// `

export default function ProjectPage() {
 
	// let { state } = useLocation();
	const activeSlug = useParams().slug;

	// const client = useApolloClient();


    const data = useRouteLoaderData("root");
    // const data = useLoaderData();
	// console.log(data);

	// const [ activeSlug, setActiveSlug ] = useState(useParams().slug);
	// const data = useRouteLoaderData("home");
	const [ activeProjectID, setActiveProjectID ] = useState();
	const [ projectList, setProjectList ] = useState(data.projectLists[0].projects);
	// console.log(projectList);


    useEffect(() => {
    	// setActiveSlug(slug);
		setActiveProjectID( 
			projectList.find(item => item.slug === activeSlug).id
		);
	    // const el = document.getElementById(activeProjectID);
    	// el?.scrollIntoView({
    	// 	behavior: "smooth",
  		// 	block: "center" ,
    	// 	inline: "center" ,
    	// })

    }, [activeSlug])


	// const { data, loading, error } = useQuery( PROJECT_BY_SLUG );
	// if (loading) return `Loading...`;
	// if (error) return `Error! ${error}`;

	// const { projectLists: [ { projects: projectList } ] } = data;

	// let activeProjectID = projectList.find(item => item.slug === activeSlug).id

	setTimeout( () => {
    	let el = document.getElementById(activeProjectID);
    	el?.scrollIntoView({
		  behavior: "smooth",
		  block: "center" ,
		  // inline: "center" ,
		});
	})

    return (
        <div className={styles.projectPageContainer}>

			
			{ projectList &&
				<ProjectList projectList={projectList} activeProjectID={activeProjectID} />
			}

        	<Outlet />

			{/*<ActiveProject />*/}

        </div>
   	)
}

function ProjectList ({ projectList, activeProjectID }) {
	return (
		<ul className={styles.projectListContainer} >
			{ projectList.map(({ id, title, mainImage, slug }, index) => (
				<Link 
					key={ id }
					id={ id }
					className={ (id === activeProjectID)
						? styles.activeProjectListItem
						: styles.projectListItem
					}
					to={`/projects/${slug}`}
				>
					<img
					    className={styles.projectImage}
					    src={mainImage.url}
					    alt={`project main image`}
					/>
				</Link>
			)) }
		</ul>
	)
}


