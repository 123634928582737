import styles from './error-page.module.css';

import { useRouteError } from "react-router-dom";
import { displayCategoryVar, projectTitleVar } from '../../lib/cache';

export default function ErrorPage() {

    const error = useRouteError();
    // console.log({error});

    projectTitleVar('ಠ_ಠ');
    
    return (

        <div id="error-page" style={{fontSize: '1rem'}}>
            
            <p> Unfortunately, an error has occurred.</p>

            <code 
                style={{
                    display:'inline-flex',
                    // fontSize: '0.99rem',
                    marginTop:'10px', 
                    padding: '0.33rem 0.55rem',
                    background: 'rgba(254, 199, 75, 0.16)',
                    border: '0.5px solid rgba(254, 199, 75, 0.2)', 
                    borderRadius: '0.5rem',
                    letterSpacing: '-0.0125rem',
                    lineHeight: '1.33'
                }} 
            >
                { error.statusText ?? error.message }
            </code>

        </div>
        
    );

}