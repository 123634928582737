import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { projectTitleVar } from './cache';
import { useReactiveVar } from '@apollo/client';

export default function SEO ({title, description, name, type}) {

	// const pageInfo = useReactiveVar(projectTitleVar);

	let {pathname} = useLocation();
	let projectTitle = useReactiveVar(projectTitleVar);

    // console.log('projectTitle ', projectTitle)


    const [pageDetail, setPageDetail] = useState('MBABAZI');

    useEffect(() => {
    	setPageDetail(
    		pathname.startsWith('/projects/')
    			? ` | ${projectTitle}`
    			: ''
    	)
    }, [pathname, projectTitle]);


	return (
		<Helmet>
			{ /* Standard metadata tags */ }

			<title> { `MBABAZI${pageDetail}` } </title>
			<meta name='description' content={description} />

			{ /* End standard metadata tags */ }
			{ /* Facebook tags */ }

			{/*
			<meta property="og:type" content={type} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			*/}

			{ /* End Facebook tags */ }
			{ /* Twitter tags */ }

			{/*
			<meta name="twitter:creator" content={name} />}
			<meta name="twitter:card" content={type} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			*/}

			{ /* End Twitter tags */ }
		</Helmet>
	)
}