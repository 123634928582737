import './App.css';

import { Outlet } from "react-router-dom";

import SEO from './lib/seo';
import Navigation from './components/navigation/navigation';
// import { imageWidthVar } from './lib/cache';


export default function App() {
    return (
        <div className="app">

            <SEO />
            <Navigation />

            <div className="content"> <Outlet/> </div>

        </div>
    );
}

