
// import { Link } from "react-router-dom";
import styles from './menu.module.css';

export default function Menu({ menuClose }) {

	const menuItems = [
		// ['Work', `/work`],
		// ['About', '/about'],
	]

	return (
		<div
			className={styles.menuContainer}
			onClick={menuClose}
		>
			<div className={styles.aboutText}>
				<p>Sasha Mbabazi is a self-taught designer & illustrator currently taking on work on a project-by-project basis in Vancouver.</p>
				<p>I enjoy putting together user interfaces & layouts, designing for screen and print, & illustrating in my free time.</p>
				<p>I use React, GraphQL, Figma, After Effects, Blender and most things in between.</p>
				<p>Find me in Vancouver or Montreal or <a href="mailto:sasha@mbabazi.co">send me an email</a>.</p>
			</div>
		</div>
	);
}