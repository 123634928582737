import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'

import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client/react';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import App from './App'

import Home, { GetProjectList } from "./pages/home/home";
import ProjectPage from "./pages/project/project";
// import { loader } from './components/ACTIVEPROJECT/activeProject-loader';
// import ActiveProject,  { GetActiveProjectData } from './components/ACTIVEPROJECT/activeProject';
import ErrorPage from "./pages/errorPage/error-page";
import NotFound from "./pages/notFound";

const client = new ApolloClient({
  // ssrMode: true,
  uri: 'https://us-west-2.cdn.hygraph.com/content/clgx5lrzk0kde01umegd30cim/master',
  cache: new InMemoryCache(),
});

const router = createBrowserRouter([{

    path: "/", 
    Component: App,
    id: "root",
    loader: () => GetProjectList(client),

    children: [
        {
            index: true,
            Component: Home, 
            ErrorBoundary: ErrorPage,
        },
        { 
            path: "projects", 
            Component: ProjectPage,
            ErrorBoundary: ErrorPage,
            children: [
                { element: <Navigate to="/" replace />, index: true },
                {
                    path: ":slug",
                    async loader({ params }) {
                        let { loader } = await import("./components/ACTIVEPROJECT/activeProject-loader");
                        return loader( client, params );
                    },
                    // loader: ({ params })=>loader(client, params),
                    lazy: () => import("./components/ACTIVEPROJECT/activeProject")
                },
            ],
        },
        { path: "404", Component: NotFound, },
        { path: "*", element: <Navigate to="/404" replace />, },
    ],
}]);


ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
    <HelmetProvider context={{}} >
        <ApolloProvider client={client} >

            <RouterProvider router={ router } />

        </ApolloProvider>
    </HelmetProvider>
    </React.StrictMode>,
);